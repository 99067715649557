









import { currentLanguage } from '@/utils/language'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MatterportSpace',

  props: {
    src: {
      type: String,
      required: true,
    },

    play: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const computedSrc = computed(() => {
      const url = new URL(props.src)
      url.searchParams.set('lang', currentLanguage.value.locale.split('-')[0])
      if (props.play) {
        url.searchParams.set('play', '1')
      }
      return url.href
    })

    return {
      computedSrc,
    }
  },
})
