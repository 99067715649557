




import appConfig from '@/app.config.json'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export interface GoogleStaticMapMarker {
  label?: string
  color?: string
  latitude: number | string
  longitude: number | string
  size?: 'tiny' | 'mid' | 'small'
}

export default defineComponent({
  name: 'GoogleStaticMapImage',

  props: {
    url: {
      type: String,
      default: 'https://maps.googleapis.com/maps/api/staticmap',
    },

    apiKey: {
      type: String,
      default:
        process.env.NODE_ENV === 'production'
          ? appConfig.google.maps.production.apiKey
          : appConfig.google.maps.staging.apiKey,
    },

    zoom: {
      type: Number,
      default: 15,
    },

    width: {
      type: String,
      default: '400',
    },

    height: {
      type: String,
      default: '400',
    },

    markers: {
      type: Array as PropType<GoogleStaticMapMarker[]>,
      default: () => [],
    },
  },

  setup(props) {
    const zoom = computed(() => `zoom=${props.zoom}`)
    const size = computed(() => `size=${props.width}x${props.height}`)
    const markers = computed(() => {
      let result: string[] = []

      for (const marker of props.markers) {
        const { latitude, longitude, ...options } = marker
        let styles = []
        for (const [key, value] of Object.entries(options)) {
          styles.push(`${key}:${value}`)
        }
        result.push(`${styles.join('%7C')}%7C${latitude},${longitude}`)
      }
      return `markers=${result.join('&')}`
    })
    const apiKey = computed(() => `key=${props.apiKey}`)
    const parameters = computed(() =>
      [zoom.value, size.value, markers.value, apiKey.value].join('&')
    )
    const source = computed(() => `${props.url}?${parameters.value}`)

    return {
      source,
    }
  },
})
