








import { PropertyDetailViewModel } from '@/services/modules/property'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyDetail',

  props: {
    detail: {
      type: Object as PropType<PropertyDetailViewModel>,
      required: true,
    },
  },

  setup(props) {
    const computedText = computed(() => {
      let title = `- ${props.detail.propertyDetailTitle}`
      if (props.detail.value) {
        title = `${title}: ${props.detail.value}`
      }
      return title
    })

    return {
      computedText,
    }
  },
})
