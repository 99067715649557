

































































import { truncate } from '@/utils/misc'
import { getPropertyMetaTitle } from '@/utils/property'
import { useProperties } from '@/composition/properties'
import SiteSection from '@/components/SiteSection.vue'
import PropertySingleSkeleton from '@/components/PropertySingleSkeleton.vue'
import PropertySingle from '@/components/PropertySingle.vue'
import { PropertyViewModel } from '@/services/modules/property'
import { currentRoute } from '@/router'
import Disqus from '@/components/DisqusForm.vue'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Property',

  components: {
    SiteSection,
    PropertySingleSkeleton,
    PropertySingle,
    Disqus,
  },

  props: {
    propertyKey: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { data, isLoading } = useProperties(
      computed(() => ({
        pageNumber: 1,
        rowsPerPage: 1,
        propertyKey: props.propertyKey,
        isDeleted: false,
      }))
    )

    const property = computed<PropertyViewModel | undefined>(
      () => data.value[0]
    )

    return {
      property,
      isLoading,
    }
  },

  metaInfo() {
    const property = this.property as PropertyViewModel | undefined
    const title = property ? getPropertyMetaTitle(property) : ''
    const description = property
      ? `${truncate(property.description, 200)}...`
      : ''
    const url = currentRoute.value?.fullPath || ''
    const image =
      property?.propertyImageList[0].path && property?.propertyImageList[0].name
        ? `${property.propertyImageList[0].path}/${property.propertyImageList[0].name}`
        : ''

    return {
      title,

      meta: [
        // Facebook Open Graph meta
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:image',
          content: image,
        },

        // Twitter meta
        {
          property: 'twitter:card',
          content: 'summary',
        },
        {
          property: 'twitter:title',
          content: title,
        },
        {
          property: 'twitter:description',
          content: description,
        },
        {
          property: 'twitter:url',
          content: url,
        },
        {
          property: 'twitter:image',
          content: image,
        },
        {
          property: 'twitter:image:alt',
          content:
            property && property.propertyImageList[0].description
              ? `${truncate(
                  property?.propertyImageList[0].description,
                  200
                )}...`
              : '',
        },
      ],
    }
  },
})
