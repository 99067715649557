




































































































































































































































































































































































































































































































































































































































import { useCurrency } from '@/composition/currency'
import PropertyDetailGroup from '@/components/PropertyDetailGroup.vue'
import MatterportSpace from '@/components/MatterportSpace.vue'
import ContactForm from '@/components/ContactForm.vue'
import ListingsSection from '@/components/ListingsSection.vue'
// import AgentReviewsSection from '@/components/AgentReviewsSection.vue'
import PropertyShareModal from '@/components/PropertyShareModal.vue'
import GoogleStaticMapImage, {
  GoogleStaticMapMarker,
} from '@/components/GoogleStaticMapImage.vue'
import {
  PropertyCategoryId,
  PropertyViewModel,
} from '@/services/modules/property'
import { formatPhoneNumber } from '@/utils/phone'
import { getPropertyPhotoTourRouteTo } from '@/utils/property'
import { compareListingStore } from '@/composition/compareListingStore'
import { VCalendarDateRange } from '@/setup/v-calendar'
import { parseISO } from 'date-fns'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'PropertySingle',

  components: {
    PropertyDetailGroup,
    MatterportSpace,
    ContactForm,
    ListingsSection,
    // AgentReviewsSection,
    PropertyShareModal,
    GoogleStaticMapImage,
  },

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },
  },

  setup(props) {
    const imageHeroList = computed(() =>
      props.estateProperty.propertyImageList.slice(
        0,
        props.estateProperty.propertyImageList.length > 1 ? 5 : 1
      )
    )

    const firstImageHero = computed(() => imageHeroList.value[0])

    const imageCount = computed(
      () => props.estateProperty.propertyImageList.length
    )

    const isSingleImageLayout = computed(() => imageCount.value === 1)

    const propertyPrice = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyPrice2 = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price2,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyCleaningFee = computed(
      () =>
        useCurrency({
          value: props.estateProperty.cleaningFee,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const isRental = computed(
      () =>
        props.estateProperty.categoryViewModel.categoryId ===
        PropertyCategoryId.Rentals
    )

    const propertyPosition = computed(() => ({
      lat: Number.parseFloat(props.estateProperty.addressViewModel.latitude),
      lng: Number.parseFloat(props.estateProperty.addressViewModel.longitude),
    }))

    const markerColor = computed(() =>
      props.estateProperty.categoryViewModel.toneViewModel.name === 'primary'
        ? '#ff681d'
        : '#0047ba'
    )

    const markerOptions = computed<google.maps.MarkerOptions>(() => ({
      position: propertyPosition.value,
      icon: {
        path: 'M17.3608 3.1584C16.1344 1.728 13.8712 0.0216 10.0672 0H9.93758C6.12878 0.0216 3.86558 1.728 2.63918 3.1584C1.18867 4.86508 0.387176 7.02902 0.375977 9.2688C0.375977 16.7088 8.87438 23.4504 9.23438 23.7336C9.45413 23.9056 9.72582 23.9979 10.0049 23.9953C10.284 23.9927 10.5539 23.8953 10.7704 23.7192C11.3152 23.3016 19.624 16.6632 19.624 9.2688C19.6128 7.02902 18.8113 4.86508 17.3608 3.1584ZM9.99998 13.2144C9.27705 13.2144 8.57035 13 7.96926 12.5984C7.36816 12.1967 6.89967 11.6259 6.62301 10.958C6.34636 10.2901 6.27397 9.55515 6.41501 8.84611C6.55605 8.13707 6.90417 7.48577 7.41536 6.97458C7.92655 6.46339 8.57784 6.11527 9.28688 5.97423C9.99592 5.8332 10.7309 5.90558 11.3988 6.18224C12.0667 6.45889 12.6375 6.92738 13.0392 7.52848C13.4408 8.12957 13.6552 8.83627 13.6552 9.5592C13.6552 10.5286 13.2701 11.4583 12.5846 12.1438C11.8991 12.8293 10.9694 13.2144 9.99998 13.2144Z',
        scale: 1.5,
        fillColor: markerColor.value,
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#fff',
        anchor: { x: 10, y: 20 } as google.maps.Point,
      },
    }))

    const staticMarkerOptions = computed<GoogleStaticMapMarker[]>(() => [
      {
        latitude: propertyPosition.value.lat,
        longitude: propertyPosition.value.lng,
        color: markerColor.value.replace('#', '0x'),
      },
    ])

    const agentPhone = computed(() =>
      formatPhoneNumber(props.estateProperty.agentViewModel.phone || '')
    )

    const agentCellPhone = computed(() =>
      formatPhoneNumber(props.estateProperty.agentViewModel.cellPhone)
    )

    const rentedDateRanges = computed(() =>
      props.estateProperty.calendarList.map<VCalendarDateRange>((item) => ({
        start: parseISO(item.startingDate),
        end: parseISO(item.endingDate),
      }))
    )

    function addToCompareListings() {
      compareListingStore.add(props.estateProperty)
    }

    const selectedEstateProperty = ref<PropertyViewModel | null>(null)
    const isShareModalOpen = ref(false)

    watch(
      () => selectedEstateProperty.value,
      (newValue) => {
        isShareModalOpen.value = Boolean(newValue)
      }
    )

    function print() {
      window.print()
    }

    return {
      imageHeroList,
      firstImageHero,
      imageCount,
      isSingleImageLayout,
      getPropertyPhotoTourRouteTo,
      propertyPrice,
      propertyPrice2,
      propertyCleaningFee,
      isRental,
      propertyPosition,
      markerOptions,
      staticMarkerOptions,
      agentPhone,
      agentCellPhone,
      rentedDateRanges,
      addToCompareListings,
      selectedEstateProperty,
      isShareModalOpen,
      print,
    }
  },
})
