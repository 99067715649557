

















import { PropertyGroupViewModel } from '@/services/modules/property'
import PropertyDetail from '@/components/PropertyDetail.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyDetailGroup',

  components: {
    PropertyDetail,
  },

  props: {
    group: {
      type: Object as PropType<PropertyGroupViewModel>,
      required: true,
    },
  },
})
